import * as yup from "yup";
import { checkAgeLimit } from "../../utils/basket";

export function makeValidationSchema(
  isSize,
  isRegion,
  isTeams,
  isCluster,
  format
) {
  return yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    secondName: yup.string(),
    size: isSize ? yup.string().required("Выберите размер!") : yup.string(),
    region: isRegion ? yup.string().required("Выберите регион!") : yup.string(),
    cluster: isCluster
      ? yup.string().required("Выберите кластер!")
      : yup.string(),
    team: isTeams ? yup.string().required("Выберите команду!") : yup.string(),
    birth: yup
      .string()
      .required()
      .test("isValidBirth", "Введите корректную дату!", (value) => {
        const newDate = value && value.split(`.`).reverse().join(`-`);
        if (
          value &&
          new Date(newDate).toLocaleDateString("en-GB") === `Invalid Date`
        )
          return false;
        if (value && value.length >= 10) return true;
      })
      .test("isMinAge", `Участие старше ${format.age_min}`, (value) => {
        const newDate = value.split(".").reverse().join("-");

        if (
          format?.age_min &&
          checkAgeLimit(newDate, format.age_min, null, format.start_time)
        ) {
          return false;
        } else {
          return true;
        }
      })
      .test("isMaxAge", `Участие младше ${format.age_max}`, (value) => {
        const newDate = value.split(".").reverse().join("-");

        if (
          format?.age_max &&
          checkAgeLimit(newDate, null, format.age_max, format.start_time)
        ) {
          return false;
        } else {
          return true;
        }
      }),
    phone: yup
      .string()
      .test("isValidPhone", "Введите корректный номер телефона", (value) => {
        if (!value || value.length !== 12) return false;
        if (value && value.length === 12) return true;
      }),
  });
}

import { convertBase64 } from "../../../utils/convertBase64";

const getNameId = (ticket) => {
  if (ticket.team_public_id) return `team_public_id`;
  return `format_public_id`;
};

export const useSubmit = ({
  gender,
  setGenderError,
  ticket,
  registrationTicket,
  fields,
  setError,
  format,
  isLoadFile,
  file,
  setFileError,
}) => {
  return {
    onSubmit: async (data) => {
      const {
        birth,
        firstName,
        lastName,
        phone,
        size,
        region,
        team,
        secondName,
        cluster,
      } = data;

      let document;
      if (file) {
        document = await convertBase64(file);
      }

      if (!document && isLoadFile) {
        setFileError("Выберите файл!");
        return;
      } else {
        setFileError("");
      }

      let additional_fields = [];
      if (fields.length) {
        additional_fields = fields.map((el) => {
          const name = el.name;

          if (data[name]?.length === 0 && el.required) {
            setError(name, { type: "custom", message: "Заполните поле!" });
          }
          return {
            value: data[name],
            name,
            label: el.label,
            required: el.required,
          };
        });
      }

      const newDate = birth.split(".").reverse().join("-");
      const birth_date = new Date(newDate);
      const idName = getNameId(ticket);

      let sendObj = {
        birth_date,
        public_id: ticket.public_id,
        first_name: firstName,
        last_name: lastName,
        [idName]: ticket.event_format.public_id,
        phone: phone?.slice(1),
        gender,
        additional_fields,
      };

      if (size && size !== "Футболка не выбрана") {
        sendObj = {
          ...sendObj,
          item_size: size,
        };
      }

      const regexp = /^[а-яА-ЯёЁ]+$/;

      if (ticket.info.insurance) {
        if (!regexp.test(firstName))
          setError("firstName", { message: "Только кирица!" });
        if (!regexp.test(lastName))
          setError("lastName", { message: "Только кирица!" });
        if (secondName.length && !regexp.test(secondName))
          setError("secondName", { message: "Только кирица!" });
      }
      if (secondName?.length && regexp.test(secondName)) {
        sendObj.second_name = secondName;
      }

      if (format && format.clusters?.length && isLoadFile) {
        sendObj = {
          ...sendObj,
          cluster: {
            name: cluster && cluster,
            document,
          },
        };
      } else if (format && format.clusters?.length && !isLoadFile) {
        sendObj = {
          ...sendObj,
          cluster: {
            name: cluster && cluster,
          },
        };
      }
      if (format && format.clusters?.length && cluster === null) return;

      if (!gender) {
        setGenderError("Выберите одно из значений!");
      }

      if (ticket.event_format.regions) {
        if (region) {
          const ticketObj = {
            ...sendObj,
            region,
          };

          registrationTicket(ticketObj);
        }
      } else if (ticket.info.type_team === "place") {
        if (team) {
          const ticketObj = {
            ...sendObj,
            team_public_id: team,
          };

          registrationTicket(ticketObj);
        }
      } else if (birth && firstName && lastName && gender) {
        registrationTicket(sendObj);
      }
    },
  };
};

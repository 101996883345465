export const getPath = (obj) => {
  switch (obj.selector) {
    case "shop":
      return `/api/promocode/check/product/${obj.code}/${obj.public_id}`;
    case "license":
      return `/api/promocode/check/insurance/${obj.code}/${obj.public_id}`;
    default:
      return `/api/promocode/check/ticket/${obj.code}/${obj.format_public_id}`;
  }
};
